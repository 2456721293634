<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Content</h1>
      <BaseInput
        class="form-control--grey form-control--xs"
        style="max-width: 65rem"
        placeholder="Search"
        :icon="true"
        :timeout="true"
        :element="tableData.search"
        @input="searchItems"
      >
        <template #icon>
          <IconSearch color="#000" height="16" width="16"></IconSearch>
        </template>
      </BaseInput>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <!-- <div class="page__header_right">
        <IconButton
          class="button--admin button--auto button--outline-grey button--ml-15"
          text="new page"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div> -->
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <ArtAdvisory
      v-if="modal"
      :modal="modal"
      :item="artAdvisory"
      @hideModal="modal = false"
      @createArtAdvisory="createArtAdvisory"
      @updateArtAdvisory="updateArtAdvisory"
      @removeArtAdvisory="confirmRemoveArtAdvisory"
    ></ArtAdvisory>
  </div>
</template>
<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import Table from "@/components/Table.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import ArtAdvisory from "./ArtAdvisory.vue";
export default {
  components: {
    BaseInput,
    IconSearch,
    HorisontalTabsList,
    Table,
    IconButton,
    IconPlusBold,
    ArtAdvisory,
  },
  data() {
    return {
      tabs: [
        {
          title: "In The News",
          path: "/admin/content/in-the-news",
          key: "in-the-news",
        },
        {
          title: "Press Releases",
          path: "/admin/content/press-releases",
          key: "pressreleases",
        },
        {
          title: "Lives Well Lived",
          path: "/admin/content/lives-well-lived",
          key: "memoriams",
        },
        {
          title: "Loose Threads",
          path: "/admin/content/loose-threads",
          key: "loose-threads",
        },
        {
          title: "Art Advisory",
          path: "/admin/content/art-advisory",
          key: "art-advisory",
        },
        {
          title: "Tags",
          path: "/admin/content/tags",
          key: "tags",
        },
      ],
      modal: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
        search: "",
      },
    };
  },
  async created() {
    await this.apiGetArtAdvisories(this.tableData);
    this.initialArtAdvisory();
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialArtAdvisory();
        this.setError(null);
      }
    },
  },
  computed: {
    ...mapGetters("artAdvisory", {
      items: "getArtAdvisories",
      headerItems: "getArtAdvisoriesHeaders",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      artAdvisory: "getArtAdvisory",
    }),
  },
  methods: {
    ...mapActions("artAdvisory", [
      "apiGetArtAdvisories",
      "apiGetArtAdvisory",
      "apiPostArtAdvisory",
      "apiPutArtAdvisory",
      "apiDeleteArtAdvisory",
    ]),
    ...mapMutations("artAdvisory", ["initialArtAdvisory", "setError"]),
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetArtAdvisories(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetArtAdvisories(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async searchItems(val) {
      if (this.tableData.search != val) {
        let data = { ...this.tableData, page: 1, search: val };
        let success = await this.apiGetArtAdvisories(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetArtAdvisory(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemoveArtAdvisory(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
      }
    },
    async createArtAdvisory({ data }) {
      let result = await this.apiPostArtAdvisory(data);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtAdvisories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
    async updateArtAdvisory({ data }) {
      let result = await this.apiPutArtAdvisory(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtAdvisories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
    confirmRemoveArtAdvisory(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>This artAdvisory will be removed <br/> everywhere it appears",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeArtAdvisory(item.id);
        }
      });
    },
    async removeArtAdvisory(id) {
      let result = await this.apiDeleteArtAdvisory(id);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtAdvisories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
}
</style>

