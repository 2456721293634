<template>
  <PopUp :show="modal" @hideModal="hideModal" bgColor="rgba(0,0,0,0.4)">
    <ValidationObserver v-slot="{ invalid }" tag="div" class="art" ref="observer">
      <header class="art__header">
        <h3 class="art__header_title">{{ artAdvisory.id ? "Edit" : "Add" }} Art Advisory</h3>
        <div class="art__header_actions">
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="
              invalid ||
              artAdvisory.preview_image === '' ||
              artAdvisory.preview_text === '' ||
              !changed
            "
            @click="saveArtAdvisory"
          ></BaseButton>
        </div>
      </header>
      <!-- <section class="form">
        <h3 class="form__header">Art Advisory Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Art Advisory Title"
              :required="true"
              :validateError="errors[0] || serverError.title"
              v-model="artAdvisory.title"
              :element="artAdvisory.title"
              @input="artAdvisory.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="artAdvisory.slug"
              :element="artAdvisory.slug"
              @input="artAdvisory.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example:
                  {{ `${url}/art-advisory/${artAdvisory.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
      </section> -->
      <section class="form">
        <h3 class="form__header">Preview</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Text"
              :required="true"
              v-model="artAdvisory.preview_title"
              :element="artAdvisory.preview_title"
              @input="artAdvisory.preview_title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--bordered">
          <span class="form__row_label"
            >Preview Image <span class="form__row_label_required">*</span>
          </span>
          <DragAndDropImage
            id="home"
            size="15"
            :image="artAdvisory.preview_image"
            :v-model="artAdvisory.preview_image"
            @input="artAdvisory.preview_image = $event.image"
            @removeImage="artAdvisory.preview_image = ''"
          ></DragAndDropImage>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Description</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 65535 }"
            v-slot="{ errors }"
          >
            <BaseTextarea
              placeholder="Description"
              :validateError="errors[0]"
              v-model="artAdvisory.description"
              :element="artAdvisory.description"
              @input="artAdvisory.description = $event"
            ></BaseTextarea>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Video</h3>
        <div
          class="form__row form__row--bordered form__row--justify-start"
          style="padding: 8px; align-items: flex-start"
        >
          <span class="form__row_label">Video </span>
          <div>
            <DragAndDropImage
              type="video"
              id="video"
              :accept="['mp4', 'mov', 'webm']"
              size="15"
              :image="artAdvisory.videos.url"
              @input="artAdvisory.videos.url = $event.image"
              @removeImage="
                artAdvisory.videos = {
                  url: '',
                  caption: '',
                }
              "
              style="margin: 4px"
            ></DragAndDropImage>
            <BaseInput
              v-if="artAdvisory.videos.url"
              class="form-control--xs"
              style="margin-top: 20px"
              placeholder="Caption"
              v-model="artAdvisory.videos.caption"
              :element="artAdvisory.videos.caption"
              @input="artAdvisory.videos.caption = $event"
            ></BaseInput>
          </div>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Images</h3>
        <div
          class="form__row form__row--bordered form__row--justify-start"
          style="padding: 8px; align-items: flex-start"
        >
          <span class="form__row_label">Images </span>
          <div v-for="(i, idx) in artAdvisory.images" :key="`image-${idx}`" style="margin: 4px">
            <DragAndDropImage
              :id="idx"
              :image="i.url"
              size="15"
              @input="artAdvisory.images.splice(idx, 1, { url: $event.image })"
              @removeImage="artAdvisory.images.splice(idx, 1)"
            ></DragAndDropImage>
            <BaseInput
              class="form-control--xs"
              style="margin-top: 20px"
              placeholder="Caption"
              v-model="i.caption"
              :element="i.caption"
              @input="i.caption = $event"
            ></BaseInput>
          </div>
          <DragAndDropImage
            size="15"
            @input="artAdvisory.images.push({ url: $event.image })"
            style="margin: 4px"
          ></DragAndDropImage>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";

import { mapGetters, mapMutations } from "vuex";
export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseInput,
    BaseButton,
    DragAndDropImage,
    BaseTextarea,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      artAdvisory: {
        id: "",
        title: "",
        slug: "",
        description: "",
        preview_title: "",
        preview_image: "",
        images: [],
        videos: {
          url: "",
          caption: "",
        },
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.artAdvisory = {
        ...JSON.parse(JSON.stringify(this.item)),
        videos: this.item.videos ? this.item.videos : { url: "", caption: "" },
      };;
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("artAdvisory", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    artAdvisory: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.title != "") {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("artAdvisory", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid &&
            this.artAdvisory.preview_image &&
            this.artAdvisory.preview_title != ""
              ? this.saveArtAdvisory()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveArtAdvisory() {
      if (this.artAdvisory.id) {
        this.$emit("updateArtAdvisory", { data: this.artAdvisory });
      } else {
        this.$emit("createArtAdvisory", { data: this.artAdvisory });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.art {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      @include type($fs: 3.4rem, $lh: 1.23, $fw: 600);
      margin-bottom: 10px;
      text-align: center;
      color: $black;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 26px;
      }
    }
    &_description {
      @include type($fs: 16px, $lh: 1.18, $fw: 500);
      text-align: center;
      color: $black;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .button {
      margin-left: 10px;
      @media only screen and (max-width: $xs) {
        margin: 20px 10px 0 0;
      }
    }
  }
}
</style>

